<template>
    <v-layout style="margin-top: 20px; height: 40px;" align-center>
        <label class="subtitle-1">{{title}}</label>
        <div class="xls-button" v-ripple v-if="loadXLS">
            <v-layout align-center fill-height justify-center @click="$emit('loadXLS')">
                <label>Экспортировать в .xls</label>
            </v-layout>
        </div>
    </v-layout>
</template>

<script>
    export default {
        name: "chart-xls-label",
        props: {
            title: {
                type: String,
                required: true
            },
            loadXLS: {
                type: Boolean
            }
        }
    }
</script>

<style scoped>
    .xls-button {
        background: #F2F2F2;
        border-radius: 8px;
        margin-left: 20px;
        width: 240px;
        height: 40px;
        color: black;
        text-align: center;
        cursor: pointer;
    }

    .xls-button * {
        cursor: pointer;
    }
</style>