<template>

</template>

<script>
    import moment from 'moment'

    export default {
        name: "xls-mixin",
        data: () =>  ({
            fileNames: {
                "cars": "Запросы_по_авто",
                "orders": "Создано_запросов",
                "priceOffers": "Добавлено_предложений",
                "carOffers": "Создано_разборок",
                "outdatedOffers": "Истекло_разборок"
            }
        }),
        computed: {
            timePeriods() {
                return this.$store.state.timePeriods
            },
        },
        methods: {
            calculateQuarter(month_no) {
                return Math.ceil((month_no + 1) / 3.0)
            },
            formFileName(type, date, period) {
                let fileName = this.fileNames[type];
                if (period === this.timePeriods.month) {
                    const formatedDate = moment(date, "YYYY-MM").format("MMMM_YYYY");
                    fileName = `${fileName}_${formatedDate}.xls`
                } else if (period === this.timePeriods.quarter) {
                    const formatedDate = moment(date, "YYYY-MM");
                    const quarter = this.calculateQuarter(formatedDate.month());
                    fileName = `${fileName}_${quarter}-й_квартал_${formatedDate.year()}.xls`
                }
                return fileName
            },
            downloadFile(type) {
                const query = this.$route.query;
                const params = {
                    headers: {token: localStorage.getItem("token")},
                    params: {
                        type: type,
                        date: query.date || new Date().toISOString().substring(0, 7),
                        period: Number(query.period) || this.timePeriods.default,
                        idCountry: query.idCountry || null,
                        idCarBrand: query.idCarBrand || null,
                        idCarModel: query.idCarModel || null,
                        idCarType: query.idCarType || 1,
                    },
                    responseType: 'blob'
                };
                console.log(params);
                this.$http.get("/statistics/xls", params)
                    .then(response => {
                          const url = window.URL.createObjectURL(new Blob([response.data]));
                          const link = document.createElement('a');
                          const fileName = this.formFileName(params.params.type, params.params.date, params.params.period);
                          link.href = url;
                          link.setAttribute('target', '_blank');
                          link.setAttribute('download', fileName);
                          link.click();
                    }).catch(error => {
                    switch (error.response.status) {
                        case 406:
                            alert("Ошибка загрузки файла");
                            break;
                        case 401:
                            this.$router.history.push({name: 'login'});
                            break;
                        default:
                            console.log('error');
                            break;
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>