<template>
    <v-layout column
              style="height: 400px;">
        <label class="headline font-weight-bold">
            {{title}}
        </label>
        <v-card class="elevation-0" style="height: 350px; width: 90%;">
            <v-overlay v-model="loading" color="white" absolute key="loader" opacity="1">
                <v-progress-circular color="secondary" indeterminate/>
            </v-overlay>

            <GChart
                    type="ColumnChart"
                    style="width :100%; height :100%; margin-left :-5px; margin-top: 20px;"
                    :data="formatedData"
                    :options="chartOptions"
            />
        </v-card>
    </v-layout>
</template>

<script>
    import {GChart} from 'vue-google-charts'

    export default {
        name: "column-chart",
        components: {GChart},
        props: {
            title: {
                type: String,
                required: true
            },
            loading: {
                type: Boolean
            },
            data: {
                type: Array
            }
        },
        computed: {
            chartOptions() {
                return {
                    animation: {
                        startup: true,
                        duration: 500
                    },
                    bar: {groupWidth: "97%"},
                    legend: {position: "none"},
                    colors: ['#E0E0E0'],
                    vAxis: {
                        textPosition: 'none',
                        gridlines: {
                            color: 'transparent'
                        },
                        maxValue: this.formatedData[1] ? (this.formatedData[2] ? this.formatedData[1][1] * 1.1 : this.formatedData[1][1]) : 0
                    },
                    hAxis: {
                        textStyle: {'textAlign': 'left'}
                    },
                    chartArea: {
                        left: 0,
                        top: 0,
                        width: '100%',
                        height: 320
                    },
                    annotations: {
                        alwaysOutside: true,
                        textStyle: {
                            fontName: 'Times-Roman',
                            color: "black"
                        }
                    }
                }
            },
            formatedData() {
                return [[{"label": ""}, {"label": ""}, {role: 'annotation'}], ...this.data]
            }
        }
    }
</script>

<style scoped>

</style>