<template>
    <v-layout column style="max-width: 300px; height: 160px;">
        <label class="subtitle-1 font-weight-regular"
               style="margin-bottom: 10px;">{{label}}</label>
        <v-fade-transition mode="out-in">
            <label v-if="!loading" class="display-1 font-weight-reqular">{{count}}</label>
            <v-progress-circular indeterminate
                                 v-else
                                 style="margin-top: 10px;"
                                 color="secondary"/>
        </v-fade-transition>
    </v-layout>
</template>

<script>
    export default {
        name: "sub-count",
        props: {
            count: {
                type: Number,
                required: true
            },
            loading: {
                type: Boolean,
                required: true
            },
            label: {
                type: String,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>