<template>
    <v-layout column>
        <v-layout style="margin-top: 25px;">
            <periods/>
        </v-layout>

        <main-count :count="offersTotalCount"
                    :loading="offersLoading"
                    label="Создано разборок"/>

        <template v-if="offersTotalCount">
            <column-chart title="По стране ТС"
                          :data="offersData"
                          :loading="offersLoading"/>
            <chart-xls-label
                    title="Показаны самые популярные страны. Данные по всем странам и областям доступны в файле."
                    @loadXLS="loadXLS('carOffers')"
                    loadXLS/>
        </template>

        <v-layout style="margin-bottom: -30px;">
            <main-count :count="outdatedTotalCount.total"
                        :loading="outdatedLoading"
                        label="Истекло разборок"/>
            <v-layout style="padding-top: 90px; max-width: 300px; margin-left: -90px;">
                <percent :count="outdatedTotalCount.extended"
                         :loading="outdatedLoading"
                         label="продлено"/>
            </v-layout>

            <v-layout style="padding-top: 50px; max-width: 150px;">
                <sub-count :count="outdatedTotalCount.reExpired"
                           :loading="outdatedLoading"
                           label="Истекло повторно"/>
            </v-layout>
            <v-layout style="padding-top: 90px; max-width: 200px;">
                <percent :count="outdatedTotalCount.reExtended"
                         :loading="outdatedLoading"
                         label="продлено"/>
            </v-layout>
        </v-layout>

        <template v-if="outdatedTotalCount.total">
            <stacked-chart title="По стране ТС"
                           :data="outdatedData"
                           :stacks="outdatedStacks"
                           :loading="outdatedLoading"/>

            <chart-xls-label
                    title="Показаны самые популярные страны. Данные по всем странам и областям доступны в файле."
                    @loadXLS="loadXLS('outdatedOffers')"
                    loadXLS/>
        </template>
    </v-layout>
</template>

<script>
    import Periods from "./inputs/Periods";
    import MainCount from "./counts/MainCount";
    import ColumnChart from "./charts/ColumnChart";
    import Percent from "./counts/Percent";
    import SubCount from "./counts/SubCount";
    import StackedChart from "./charts/StackedChart";
    import ChartXlsLabel from "./charts/ChartXlsLabel";
    import XlsMixin from "../../mixins/XlsMixin";

    export default {
        components: {
            ChartXlsLabel,
            StackedChart,
            SubCount,
            Percent,
            ColumnChart,
            MainCount,
            Periods
        },
        mixins: [XlsMixin],
        name: "statistics-by-offers",
        data: () => ({
            offersLoading: false,
            offersTotalCount: 0,
            offersData: [],
            outdatedLoading: false,
            outdatedData: [],
            outdatedTotalCount: {
                total: 0,
                extended: 0,
                reExpired: 0,
                reExtended: 0
            },
            outdatedStacks: ["Продлено", "Истекло разборок"]
        }),
        computed: {
            chartLabel() {
                return this.$store.state.chartLabel
            },
            timePeriods() {
                return this.$store.state.timePeriods
            }
        },
        watch: {
            '$route'() {
                this.getOffersData();
                this.getOutdatedData()
            }
        },
        methods: {
            getOffersData() {
                this.offersLoading = true;
                const query = this.$route.query;

                let params = {
                    headers: {token: localStorage.getItem('token')},
                    params: {
                        period: query.period || this.timePeriods.default,
                        date: query.date || new Date().toISOString().substring(0, 7)
                    }
                };
                console.log(params.params);
                this.$http.get("/statistics/offers", params)
                    .then(response => {
                        this.offersTotalCount = response.data.data.totalCount;
                        this.offersData = response.data.data.items;
                        console.log(response.data.data)
                    })
                    .catch(error => {
                        switch (error.response.status) {
                            case 406:
                                // this.$router.history.push({name: 'login'})
                                break;
                            case 401:
                                this.$router.history.push({name: 'login'});
                                break;
                            default:
                                console.log('error');
                                break;
                        }
                    }).finally(() => {
                    setTimeout(() => {
                        this.offersLoading = false;
                    }, 500)
                })

            },
            getOutdatedData() {
                this.outdatedLoading = true;
                const query = this.$route.query;

                let params = {
                    headers: {token: localStorage.getItem('token')},
                    params: {
                        period: query.period || this.timePeriods.default,
                        date: query.date || new Date().toISOString().substring(0, 7)
                    }
                };
                console.log(params.params);
                this.$http.get("/statistics/offers/outdated", params)
                    .then(response => {
                        this.outdatedTotalCount = response.data.data.totalCount;
                        this.outdatedData = response.data.data.items;
                    })
                    .catch(error => {
                        switch (error.response.status) {
                            case 406:
                                // this.$router.history.push({name: 'login'})
                                break;
                            case 401:
                                this.$router.history.push({name: 'login'});
                                break;
                            default:
                                console.log('error');
                                break;
                        }
                    }).finally(() => {
                    setTimeout(() => {
                        this.outdatedLoading = false;
                    }, 500)
                })

            },
            loadXLS(type) {
                this.downloadFile(type)
            }
        },
        beforeMount() {
            this.getOffersData();
            this.getOutdatedData()
        }
    }
</script>

<style scoped>

</style>