<template>
    <v-layout column>
        <label class="subtitle-2 font-weight-regular">Период</label>
        <v-layout>
            <v-radio-group v-model="period" row @change="changePeriod">
                <v-radio v-for="p in timePeriods.items"
                         :label="p.title"
                         :key="p.value"
                         color="secondary"
                         :value="p.value"/>
            </v-radio-group>

            <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    nudge-top="30"
                    min-width="290px"
            >
                <template v-slot:activator="{ on }">
                    <v-text-field filled style="max-width: 200px; margin: 10px 0 0 15px;"
                                  hide-no-data
                                  v-model="selectedDateTitle"
                                  v-on="on"
                                  color="secondary"
                                  readonly
                                  append-icon="mdi-menu-down"
                                  dense/>
                </template>
                <v-date-picker :value="selectedDate"
                               no-title
                               type="month"
                               :first-day-of-week="1"
                               locale="ru"
                               @input="changeDate"
                               :multiple="period === timePeriods.quarter"
                               :max="maxMonth"
                               :min="minYear"
                               color="secondary"/>
            </v-menu>
        </v-layout>
    </v-layout>
</template>

<script>
    import moment from 'moment'

    export default {
        name: "periods",
        data: () => ({
            period: null,
            items: [],
            minYear: "2020",
            selectedDate: null,
            selectedDateTitle: "",
            menu: false
        }),
        computed: {
            timePeriods() {
                return this.$store.state.timePeriods
            },
            maxMonth() {
                return new Date().toISOString().substr(0, 7)
            }
        },
        watch: {
            '$route'(to) {
                this.parsePeriod();
                this.parseDate();
                this.menu = false;
            }
        },
        methods: {
            parsePeriod() {
                this.period = this.$route.query.period ? parseInt(this.$route.query.period) : 1
            },
            parseDate() {
                this.selectedDate = this.$route.query.date || new Date().toISOString().substr(0, 7)

                if (this.period === this.timePeriods.month) {
                    let title = moment(this.selectedDate).format("MMMM YYYY");
                    title = title.charAt(0).toUpperCase() + title.slice(1);
                    this.selectedDateTitle = title
                } else if (this.period === this.timePeriods.quarter) {
                    const date = moment(this.selectedDate);
                    this.selectedDateTitle = `${this.calculateQuarter(date.month())}-й квартал ${date.year()}`
                    this.filterMonths(this.$route.query.date)
                }
            },
            changePeriod(value) {
                let query = {...this.$route.query} || {};
                if (value === this.timePeriods.quarter) {
                    const date = moment(this.selectedDate, "YYYY-MM");
                    const quarter = this.calculateQuarter(Number(date.month()));
                    const month = (quarter - 1) * 3 + 1;
                    query.date = moment(`${date.year()}-${month}`, "YYYY-M").format("YYYY-MM")
                }
                this.$router.history.push({
                    name: this.$route.name, query: {...query, period: value}
                })
            },
            changeDate(value) {
                if (this.period === this.timePeriods.month) {
                    const query = this.$route.query || {};
                    this.$router.history.push({
                        name: this.$route.name, query: {...query, date: moment(value).format("YYYY-MM")}
                    })
                } else if (this.period === this.timePeriods.quarter) {
                    if (value.length >= this.selectedDate.length) {
                        this.filterMonths(value.slice(-1)[0]);
                        const query = this.$route.query || {};
                        this.$router.history.push({
                            name: this.$route.name, query: {...query, date: moment(this.selectedDate[0]).format("YYYY-MM")}
                        })
                    }
                }

            },
            calculateQuarter(month_no) {
                return Math.ceil((month_no + 1) / 3.0)
            },
            filterMonths(value) {
                const lastValue = moment(value);

                const quarter = this.calculateQuarter(Number(lastValue.format("M")) - 1);
                const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

                this.selectedDate = months.map(v => {
                    if (this.calculateQuarter(v) === quarter) {
                        const date = moment(`${lastValue.year()}-${v + 1}`, "YYYY-M").format("YYYY-MM");
                        if (date <= this.maxMonth) return date
                    }
                }).filter(v => !!v)
            }
        },
        created() {
            this.parsePeriod();
            this.parseDate()
        }
    }
</script>

<style scoped>

</style>